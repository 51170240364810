import { ReactNode } from 'react';
import cn from 'classnames';
import { Icon } from '@/components/ui/Icon';

export type BannerProps = {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  className?: string | undefined;
};

export const Banner = ({ visible = false, onClose, title, children, className }: BannerProps) => {
  if (visible) {
    return (
      <div className={cn('banner flex flex-col', className)}>
        <div className="flex flex-row justify-between">
          <p className="font-bold">{title}</p>
          <button aria-label="Banner schließen" aria-describedby="Schließt das Banner" className="w-6 h-6 ml-1" onClick={onClose}>
            <Icon name={'Close'} />
          </button>
        </div>
        {children}
      </div>
    );
  } else {
    return <></>;
  }
};
