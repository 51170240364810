import { Banner } from './Banner';
import { useSetting } from '@/contexts/SettingsContext';
import { useLanguage } from '@/contexts/LanguageContext';

export const JCDigitalNotice = () => {
  const { t } = useLanguage();

  const [noticeDismissed, setNoticeDismissed] = useSetting('noticeDismissed');

  return (
    <Banner
      className="p-4 my-6 w-full gap-2"
      visible={!noticeDismissed}
      onClose={() => setNoticeDismissed(true)}
      title={t.home.banner.title}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t.home.banner.paragraph1,
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: t.home.banner.paragraph2,
        }}
      />
    </Banner>
  );
};
