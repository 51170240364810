import React, { FC } from 'react';
import { Link, useLanguage } from '@/contexts/LanguageContext';
import { useLayout } from '@/contexts/LayoutContext';
import { Icon } from '../Icon';
import { JCDigitalNotice } from '@/components/ui/Info/JCDigitalNotice';
import useBreakpoint, { Breakpoints } from '@/hooks/useBreakpoint';

export type HomePageHeaderProps = {
  buildTime: {
    minutes: number;
    hours: number;
  };
};
export const HomepageHeader: FC<HomePageHeaderProps> = ({ buildTime }) => {
  const { t } = useLanguage();
  const { setSearchOpen } = useLayout();
  const isMobile = useBreakpoint(Breakpoints.lg);

  const { greetings } = t.home.header;
  const greetingLimits = {
    300: {
      text: greetings.morning,
      emoji: 'coffee',
    },
    690: {
      text: greetings.noon,
      emoji: 'wave',
    },
    840: {
      text: greetings.afternoon,
      emoji: 'sun',
    },
    1080: {
      text: greetings.evening,
      emoji: 'moon',
    },
    1440: {
      text: greetings.night,
      emoji: 'sleep',
    },
  };

  const minutesPassed = buildTime.hours * 60 + buildTime.minutes;
  const greetingIndex =
    Object.keys(greetingLimits)
      .sort((a, b) => Number(b) - Number(a))
      .find((limit) => Number(limit) < minutesPassed) ?? 1440;
  const { text, emoji } = greetingLimits[greetingIndex];

  return (
    <>
      <div className="relative flex flex-col md:items-start">
        <Link href="[lang]/">
          <a className="mb-10 md:mb-8">
            <img src="/static/Jobcenter Logo.svg" className="h-8 mx-auto md:h-12" alt="Jobcenter Logo" />
          </a>
        </Link>
        {!isMobile && <JCDigitalNotice />}
        <h1 className="w-full mb-6 text-xl md:pr-8 md:mb-10 lg:mb-8 md:text-3xl direction-switch md:leading-tight">
          <strong className="flex font-bold">
            {text} <img src={`/static/emojis/${emoji}.svg`} className="inline h-10 ml-2" alt="" />
          </strong>
          <span data-cy="homepageHelpText" className="block md:text-2xl">
            {t.home.header.subtitle}
          </span>
        </h1>
      </div>
      <div className="z-10 top-4 lg:w-1/2 lg:pr-4 direction-switch">
        <div className="relative">
          <Icon name="Search" className="absolute w-5 h-5 mx-2 my-auto top-2 bottom-2" />
          <label>
            <input
              className="w-full px-10 py-2 rounded-lg shadow appearance-none"
              type="text"
              data-cy="homepageSearchInput"
              placeholder={t.general.search}
              onClick={() => setSearchOpen(true)}
            />
            <span className="sr-only">{t.general.search}</span>
          </label>
        </div>
        {isMobile && <JCDigitalNotice />}
      </div>
    </>
  );
};
